h1, h2, h3, h4, h5, h6, p, li {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: National;
  src: url("../fonts/national-bold.1c24e597.woff2") format("woff");
  font-weight: bold;
  font-stretch: normal;
}

@font-face {
  font-family: National;
  src: url("../fonts/national-regular.42be71d5.woff2") format("woff");
  font-weight: normal;
  font-stretch: normal;
}

@font-face {
  font-family: National;
  src: url("../fonts/national-semibold.69ee828e.woff2") format("woff");
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: National;
  src: url("../fonts/national-light.5709af85.woff2") format("woff");
  font-weight: 300;
  font-stretch: normal;
}

main {
  background: #fff;
}

* {
  box-sizing: border-box;
}

body:not(.at-top) header {
  background: #252525;
}

header {
  z-index: 100;
  position: sticky;
  top: 0;
}

header .container {
  width: 100%;
  z-index: 1;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 5%;
  display: flex;
  position: relative;
}

@media (min-width: 1400px) {
  header .container {
    max-width: 1400px;
    padding: 0 50px;
  }
}

header .logo {
  z-index: 5;
}

@media (min-width: 1200px) {
  header .logo {
    display: flex;
  }
}

header .menu-toggle {
  z-index: 5;
}

@media (max-width: 1199px) {
  header h1 {
    margin-bottom: 0;
  }
}

header h1 a {
  width: 200px;
  height: 40px;
  text-indent: -9999px;
  background-image: url("../media/wire-screen-logo.16f5dad5.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: auto;
  display: inline-block;
}

@media (min-width: 1200px) {
  header h1 a {
    width: 300px;
    height: 60px;
  }
}

header .text-items {
  height: 20px;
  letter-spacing: .6px;
  text-transform: uppercase;
  color: #bebebe;
  margin: 8px 0 0;
  font-family: National;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
}

@media (min-width: 1200px) {
  header .text-items {
    margin-top: auto;
    margin-bottom: 18px;
    padding-left: 5px;
    font-size: 20px;
  }
}

.navopen header .text-items {
  color: #222;
}

header .text-items .spacer {
  width: 20px;
}

header [data-words] {
  overflow: hidden;
}

header [data-words]:after {
  display: none !important;
}

header .text-anim-items {
  height: 20px;
  width: 300px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

header .text-anim-item {
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  left: 0;
}

header .text-anim-item.anim-in {
  -webkit-transform: translate3d(-50%, -120%, 0);
  animation: .6s .3s forwards textAnimIn;
  transform: translate3d(0, -120%, 0);
}

header .text-anim-item.anim-out {
  animation: .6s forwards textAnimOut;
  transform: translate3d(0, 0%, 0);
}

@keyframes textAnimIn {
  0% {
    transform: translate3d(0, 120%, 0);
  }

  100% {
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes textAnimOut {
  0% {
    transform: translate3d(0, 0%, 0);
  }

  100% {
    transform: translate3d(0, -120%, 0);
  }
}

header h2 {
  letter-spacing: .6px;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  font-family: National;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
}

@media (min-width: 1200px) {
  header h2 {
    margin-top: auto;
    margin-bottom: 18px;
    padding-left: 20px;
    font-size: 20px;
  }
}

.navopen header h2 {
  color: #222;
}

header nav {
  visibility: hidden;
  opacity: 0;
  margin-top: auto;
  margin-bottom: 13px;
}

@media (min-width: 800px) {
  header nav {
    visibility: visible;
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  header nav {
    margin-bottom: 13px;
  }
}

@media (max-width: 799px) {
  header nav {
    width: 100%;
    background: #fff;
    padding: 100px 5% 40px;
    transition: all .5s;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 4px 20px #0003;
  }

  header nav ul {
    display: inline-block !important;
  }

  header nav ul li a {
    margin-left: 0 !important;
  }
}

.navopen header nav {
  visibility: visible;
  opacity: 1;
}

header nav ul {
  margin: 0;
  padding: 0;
  display: flex;
}

header nav ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

header nav ul li:nth-of-type(1) a {
  margin-left: 0;
}

header nav ul li a {
  color: #bdbdbd;
  font-family: National;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  text-decoration: none;
  transition: all .5s;
}

@media (min-width: 800px) {
  header nav ul li a {
    margin-left: 20px;
    font-size: 26px;
    line-height: 38px;
  }
}

@media (min-width: 1100px) {
  header nav ul li a {
    margin-left: 30px;
    font-size: 28px;
    line-height: 34px;
  }
}

header nav ul li a:visited {
  color: #bdbdbd;
}

header nav ul li a:hover {
  color: #61bb90;
}

header nav ul li a.red, header nav ul li a.red:visited {
  color: #db3b25;
}

header nav ul li a.green, header nav ul li a.green:visited {
  color: #61bb90;
}

header nav ul li a.green:hover {
  color: #db3b25;
}

#background-video {
  height: 100%;
  object-fit: cover;
  width: 100%;
  z-index: 0;
  background: #fff3ec;
  position: absolute;
  inset: 0;
}

#main {
  background: #f1f1f1;
  margin-top: -103px;
  position: relative;
}

#main .container {
  width: 100%;
  z-index: 1;
  margin: 0 auto;
  padding: 200px 5%;
  position: relative;
}

@media (min-width: 1300px) {
  #main .container {
    max-width: 1200px;
    padding: 200px 50px 300px;
  }
}

#main .container h1 {
  max-width: 820px;
  text-align: center;
  color: #fff;
  margin: 0 auto 50px;
  font-family: National;
  font-size: 35px;
  font-style: normal;
  font-weight: 300;
  line-height: 45px;
}

@media (min-width: 400px) {
  #main .container h1 {
    font-size: 48px;
    line-height: 60px;
  }
}

@media (min-width: 800px) {
  #main .container h1 {
    margin-bottom: 50px;
    font-size: 120px;
    line-height: 100%;
  }
}

#main .container .button {
  letter-spacing: .06em;
  text-transform: uppercase;
  color: #222;
  background: #61bb90;
  border-radius: 8px;
  margin: 0 auto 65px;
  padding: 10px 20px 11px;
  font-family: National;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-decoration: none;
  transition: all .5s;
  display: table;
}

#main .container .button:hover {
  color: #fff;
  background-color: #db3b25;
}

#main .container p {
  max-width: 600px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media (min-width: 800px) {
  #main .container p {
    font-size: 20px;
    line-height: 26px;
  }
}

#map {
  padding: 0 0 40px;
  position: relative;
}

@media (min-width: 800px) {
  #map {
    padding: 0 0 80px;
  }
}

#map .container {
  width: 100%;
  z-index: 1;
  padding: 0 5%;
  position: relative;
}

#map .internal-container {
  background: #fff;
  flex-wrap: wrap;
  padding: 10% 0;
}

@media (min-width: 800px) {
  #map .internal-container {
    max-width: 90%;
    justify-content: space-between;
    margin: 0 auto;
    padding: 48px 0;
    display: flex;
  }
}

@media (min-width: 1100px) {
  #map .internal-container {
    max-width: 1000px;
  }
}

#map .internal-container h2 {
  width: 100%;
  letter-spacing: 0;
  text-align: left;
  margin: 0 0 16px;
  font-family: National;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
}

#map .internal-container .image-holder {
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 40px;
}

@media (min-width: 500px) {
  #map .internal-container .image-holder {
    height: 300px;
  }
}

@media (min-width: 800px) {
  #map .internal-container .image-holder {
    width: 48%;
    height: 200px;
    margin-bottom: 0;
  }
}

@media (min-width: 1000px) {
  #map .internal-container .image-holder {
    width: 230px;
    height: 317px;
    margin-bottom: 0;
  }
}

#map .internal-container .image-holder img {
  object-fit: contain;
  height: 200px;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: 500px) {
  #map .internal-container .image-holder img {
    height: 300px;
  }
}

@media (min-width: 800px) {
  #map .internal-container .image-holder img {
    height: auto;
  }
}

#map .internal-container .copy {
  color: #222;
  margin: 0;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

@media (min-width: 800px) {
  #map .internal-container .copy {
    width: 48%;
  }
}

@media (min-width: 1000px) {
  #map .internal-container .copy {
    width: calc(100% - 300px);
  }
}

#map .internal-container .copy p {
  max-width: 700px;
}

#map .internal-container .copy .vert {
  width: 100%;
  margin: 0;
}

@media (min-width: 1200px) {
  #map .internal-container .copy .vert {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

#wealth {
  background: #f1f1f1;
  padding: 40px 0;
}

@media (min-width: 800px) {
  #wealth {
    padding: 80px 0;
  }
}

#wealth .container {
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
}

@media (min-width: 1400px) {
  #wealth .container {
    max-width: 1400px;
    padding: 0 50px;
  }
}

@media (min-width: 1000px) {
  #wealth .container {
    flex-direction: row-reverse;
    justify-content: space-between;
    display: flex;
  }
}

#wealth .container .copy {
  margin-bottom: 40px;
}

@media (min-width: 1000px) {
  #wealth .container .copy {
    width: 45%;
    margin-bottom: 0;
  }
}

#wealth .container .copy h2 {
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 16px;
  font-family: National;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
}

#wealth .container .copy p {
  color: #222;
  margin: 0 0 20px;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

#wealth .container .copy p:last-of-type {
  margin-bottom: 0;
}

#wealth .container .video-holder {
  width: 100%;
  margin-bottom: 40px;
}

@media (min-width: 1000px) {
  #wealth .container .video-holder {
    width: 51%;
    margin-bottom: 0;
  }
}

#wealth .container .video-holder img {
  max-width: 100%;
}

#wealth .container .video-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

#wealth .container .video-container:after {
  content: "";
  padding-top: 56.25%;
  display: block;
}

#wealth .container .video-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#platform {
  background: #fff;
  padding: 80px 0;
}

#platform .container {
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
}

@media (min-width: 1400px) {
  #platform .container {
    max-width: 1400px;
    padding: 0 50px;
  }
}

@media (min-width: 800px) {
  #platform .container {
    justify-content: space-between;
    display: flex;
  }
}

#platform .container h2 {
  color: #222;
  margin: 0 0 24px;
  font-family: National;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}

@media (min-width: 800px) {
  #platform .container h2 {
    width: 47%;
    margin-bottom: 0;
    font-size: 60px;
    line-height: 72px;
  }
}

#platform .container p {
  color: #222;
  margin: 0;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media (min-width: 800px) {
  #platform .container p {
    width: 47%;
  }
}

#services {
  background: #fff;
  padding: 40px 0;
  position: relative;
}

@media (min-width: 800px) {
  #services {
    padding: 55px 0 60px;
  }
}

#services .container {
  width: 100%;
  z-index: 1;
  margin: 0 auto;
  padding: 0 5%;
  position: relative;
}

@media (min-width: 1400px) {
  #services .container {
    max-width: 1400px;
    padding: 0 50px;
  }
}

#services .internal-container {
  background: #fff;
}

#services .internal-container h2 {
  color: #222;
  margin-top: 0;
  margin-bottom: 24px;
  font-family: National;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

@media (min-width: 800px) {
  #services .internal-container h2 {
    margin: 0 0 48px;
    font-size: 60px;
    line-height: 72px;
  }
}

@media (min-width: 1000px) {
  #services .internal-container .articles {
    justify-content: space-between;
    display: flex;
  }
}

#services .internal-container .articles article {
  text-align: center;
  margin-bottom: 48px;
}

@media (min-width: 1000px) {
  #services .internal-container .articles article {
    text-align: left;
    width: 31%;
    margin-bottom: 0;
  }
}

#services .internal-container .articles article img {
  height: 180px;
  width: auto;
  max-width: 100%;
  object-fit: cover;
}

@media (min-width: 1000px) {
  #services .internal-container .articles article img {
    height: auto;
  }
}

#services .internal-container .articles article h3 {
  color: #222;
  margin: 20px 0 8px;
  font-family: National;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

@media (min-width: 800px) {
  #services .internal-container .articles article h3 {
    font-size: 40px;
    line-height: 120%;
  }
}

#services .internal-container .articles article p {
  color: #222;
  margin: 0 0 20px;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

#services .internal-container .articles article a {
  letter-spacing: 0;
  text-align: left;
  color: #fa0101;
  font-family: National;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

#team {
  background: #f1f1f1;
  padding: 40px 0;
}

@media (min-width: 800px) {
  #team {
    padding: 60px 0;
  }
}

#team .container {
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
}

@media (min-width: 1400px) {
  #team .container {
    max-width: 1400px;
    padding: 0 50px;
  }
}

#team .container .team {
  margin-bottom: 48px;
}

#team .container .team h2 {
  color: #222;
  margin-top: 0;
  margin-bottom: 24px;
  font-family: National;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}

@media (min-width: 800px) {
  #team .container .team h2 {
    margin-bottom: 16px;
    font-size: 60px;
    line-height: 72px;
  }
}

#team .container .team p {
  color: #222;
  margin: 0 0 20px;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

#team .container .people {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

#team .container .people .member-holder {
  width: 48%;
}

@media (max-width: 799px) {
  #team .container .people .member-holder {
    width: 100%;
    margin-bottom: 48px;
  }
}

#team .container .people .outside-copy {
  color: #222;
  margin-bottom: 40px;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (min-width: 800px) {
  #team .container .people .outside-copy {
    display: none;
  }

  #team .container .people .team-member {
    margin-bottom: 80px;
  }
}

@media (min-width: 400px) {
  #team .container .people .team-member {
    justify-content: space-between;
    display: flex;
  }
}

#team .container .people .team-member .inside-copy {
  display: none;
}

@media (min-width: 800px) {
  #team .container .people .team-member .inside-copy {
    display: inline-block;
  }
}

#team .container .people .team-member img {
  width: 104px;
  height: 104px;
  background: #dcdcdc;
  border: none;
  border-radius: 50%;
}

@media (min-width: 800px) {
  #team .container .people .team-member img {
    width: 161px;
    height: 161px;
  }
}

#team .container .people .team-member p {
  color: #222;
  margin-top: 7px;
  margin-bottom: 0;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

@media (min-width: 800px) {
  #team .container .people .team-member p {
    margin-top: 0;
  }
}

@media (min-width: 400px) {
  #team .container .people .team-member p {
    width: calc(100% - 124px);
  }
}

@media (min-width: 800px) {
  #team .container .people .team-member p {
    width: calc(100% - 181px);
  }
}

@media (min-width: 400px) and (max-width: 799px) {
  #team .container .people .team-member p strong {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
}

#investors {
  background: #61bb90;
  padding: 40px 0;
}

@media (min-width: 800px) {
  #investors {
    padding: 60px 0 100px;
  }
}

#investors .container {
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
}

@media (min-width: 1400px) {
  #investors .container {
    max-width: 1400px;
    padding: 0 50px;
  }
}

#investors .container .investors-heading {
  margin-bottom: 48px;
}

#investors .container .investors-heading h2 {
  color: #222;
  margin-top: 0;
  margin-bottom: 24px;
  font-family: National;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}

@media (min-width: 800px) {
  #investors .container .investors-heading h2 {
    margin-bottom: 16px;
    font-size: 60px;
    line-height: 72px;
  }
}

#investors .container .investors-heading p {
  color: #222;
  margin: 0 0 20px;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

#investors .container .investors-body {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  display: flex;
}

#investors .container .investors-body .investor {
  width: 640px;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 72px 0 60px;
  display: flex;
  box-shadow: 0 20px 20px #278557;
}

@media (max-width: 799px) {
  #investors .container .investors-body .investor {
    width: 100%;
    margin-bottom: 48px;
  }
}

#investors .container .investors-body .investor img {
  display: block;
}

#investors .container .investors-body .investor img.logo {
  margin-bottom: 20px;
}

#investors .container .investors-body .investor img.avatar {
  width: 104px;
  height: 104px;
  background: #dcdcdc;
  border: none;
  border-radius: 50%;
  margin: 20px;
}

@media (min-width: 800px) {
  #investors .container .investors-body .investor img.avatar {
    width: 161px;
    height: 161px;
  }
}

#investors .container .investors-body .investor p {
  text-align: center;
  color: #222;
  margin-top: 7px;
  margin-bottom: 0;
  font-family: National;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

@media (min-width: 800px) {
  #investors .container .investors-body .investor p {
    margin-top: 0;
  }
}

@media (min-width: 400px) and (max-width: 799px) {
  #investors .container .investors-body .investor p strong {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
}

.footer-button .button {
  letter-spacing: .06em;
  text-transform: uppercase;
  color: #222;
  background: #61bb90;
  border-radius: 8px;
  margin: 24px 0 36px;
  padding: 10px 20px 11px;
  font-family: National;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-decoration: none;
  transition: all .5s;
  display: table;
}

.footer-button .button:hover {
  color: #fff;
  background-color: #db3b25;
}

footer {
  color: #fff;
  background: #222;
  padding: 40px 0;
}

footer .container {
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
}

@media (min-width: 1400px) {
  footer .container {
    max-width: 1400px;
    padding: 0 50px;
  }
}

footer .top-border {
  border-top: 1px solid #4f4f4f;
  padding: 30px 0;
}

footer .logo {
  z-index: 5;
}

@media (min-width: 800px) {
  footer .logo {
    display: flex;
  }
}

@media (max-width: 799px) {
  footer h1 {
    margin-bottom: 0;
  }
}

footer h1 a {
  width: 200px;
  height: 40px;
  text-indent: -9999px;
  background-image: url("../media/wire-screen-logo.16f5dad5.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: auto;
  display: inline-block;
}

@media (min-width: 800px) {
  footer h1 a {
    width: 300px;
    height: 60px;
  }
}

footer h2 {
  letter-spacing: .6px;
  text-transform: uppercase;
  color: #bebebe;
  margin: 5px 0 20px;
  font-family: National;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
}

@media (min-width: 800px) {
  footer h2 {
    margin-top: auto;
    margin-bottom: 18px;
    padding-left: 5px;
    font-size: 20px;
  }
}

footer .footer-main-menu a {
  color: #edede9;
  font-family: National;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  transition: all .5s;
}

@media (max-width: 399px) {
  footer .footer-main-menu a {
    margin-bottom: 20px;
    display: inline-block;
  }
}

footer .footer-main-menu a:visited {
  color: #edede9;
}

footer .footer-main-menu a:hover {
  color: #61bb90;
}

footer .footer-main-menu a.red, footer .footer-main-menu a.red:visited {
  color: #db3b25;
}

footer .footer-main-menu a.green, footer .footer-main-menu a.green:visited {
  color: #61bb90;
}

footer .footer-main-menu a.green:hover {
  color: #db3b25;
}

footer .footer-sub-menu a {
  color: #bdbdbd;
  font-family: National;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  transition: all .5s;
}

@media (max-width: 399px) {
  footer .footer-sub-menu a {
    margin-bottom: 20px;
    display: inline-block;
  }
}

footer .footer-sub-menu a:visited {
  color: #bdbdbd;
}

footer .footer-sub-menu a:hover {
  color: #61bb90;
}

footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 400px) {
  footer ul {
    display: flex;
  }
}

footer ul li {
  color: #bdbdbd;
  margin: 0;
  padding: 0;
  font-family: National;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  list-style: none;
}

@media (min-width: 400px) {
  footer ul li {
    padding-left: 32px;
  }
}

footer ul li:nth-of-type(1) {
  padding-left: 0;
}

footer ul li a {
  color: #fff;
  text-decoration: none;
}

.menu-toggle {
  color: #fff;
  width: 60px;
  height: 60px;
  cursor: pointer;
  text-transform: none;
  background: none;
  border: none;
  margin: 20px -20px 0 0;
  padding: 0;
  font-size: 0;
  transition: background .3s, margin-top .3s;
  display: block;
  position: relative;
}

.menu-toggle:hover {
  background: none;
}

@media (min-width: 800px) {
  .menu-toggle {
    opacity: 0;
    z-index: -1;
    position: absolute;
    left: -9999px;
  }
}

.navopen .menu-toggle span:before, .navopen .menu-toggle span:after {
  background-color: #222;
}

.menu-toggle:focus {
  outline: none;
}

.menu-toggle span {
  height: 2px;
  background: #fff;
  font-size: 0;
  display: block;
  position: absolute;
  top: 30px;
  left: 20px;
  right: 20px;
}

@media (min-width: 756px) {
  .at-top .menu-toggle span {
    top: 50px;
  }
}

.menu-toggle span:before, .menu-toggle span:after {
  width: 100%;
  height: 2px;
  content: "";
  background-color: #fff;
  display: block;
  position: absolute;
  left: 0;
}

.menu-toggle span:before {
  top: -8px;
}

.menu-toggle span:after {
  bottom: -8px;
}

.menu-toggle span {
  transition: background 0s .3s;
}

.menu-toggle span:before, .menu-toggle span:after {
  transition-duration: .3s, .3s;
  transition-delay: .3s, 0s;
}

.menu-toggle span:before {
  transition-property: top, transform;
}

.menu-toggle span:after {
  transition-property: bottom, transform;
}

.menu-toggle.is-active:after {
  content: "Close";
}

.menu-toggle.is-active span {
  background: none;
}

.menu-toggle.is-active span:before {
  top: 0;
  transform: rotate(45deg);
}

.menu-toggle.is-active span:after {
  bottom: 0;
  transform: rotate(-45deg);
}

.menu-toggle.is-active span:before, .menu-toggle.is-active span:after {
  transition-delay: 0s, .3s;
}

/*# sourceMappingURL=index.51b83dd0.css.map */
