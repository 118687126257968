// Variables
$container-width: 1300px;

$color--black: #222222;
$color--grey: #f1f1f1;
$color--red: #db3b25;
$color--green: #61bb90;

//Mixins

// show and hide stuff
@mixin hide {
    left: -9999px;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

@mixin show {
    left: auto;
    opacity: 1;
    position: relative;
    z-index: 1;
}


//Fonts

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
    -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
    -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
}


@font-face {
    font-family: 'National';
    src: url('../fonts/national-bold.woff2') format('woff');
    font-weight: bold;
    font-stretch: normal;
}

@font-face {
    font-family: 'National';
    src: url('../fonts/national-regular.woff2') format('woff');
    font-weight: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'National';
    src: url('../fonts/national-semibold.woff2') format('woff');
    font-weight: 500;
    font-stretch: normal;
}

@font-face {
    font-family: 'National';
    src: url('../fonts/national-light.woff2') format('woff');
    font-weight: 300;
    font-stretch: normal;
}




main {
    background:  #fff;
}

* {
    box-sizing: border-box;
}

body:not(.at-top) header {
    background: #252525;
}

header {
    position: sticky;
    z-index: 100;
    top: 0;

    .container {
        width: 100%;
        padding: 0 5%;
        @media all and (min-width: $container-width + 100px) {
            max-width: $container-width + 100px;
            padding: 0 50px;
        }
        margin: 0 auto 0;
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .logo {
        z-index: 5;
        @media all and (min-width: 1200px) {
            display: flex;
        }
    }

    .menu-toggle {
        z-index: 5;
    }

    h1 {
        @media all and (max-width: 1199px) {
            margin-bottom: 0;
        }


        a {
            background-image: url('../media/wire-screen-logo.svg');
            background-repeat: no-repeat;
            width: 200px;
            height: 40px;
            background-size: contain;

            @media all and (min-width: 1200px) {
                width: 300px;
                height: 60px;
            }
            display: inline-block;
            text-indent: -9999px;
            margin-top: auto;
        }
    }

    .text-items {
        height: 20px;
        font-size: 17px;
        font-family: 'National';
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: .6px;
        display: inline-block;
        text-transform: uppercase;
        color: #bebebe;
        margin: 0;
        margin-top: 8px;
        @media all and (min-width: 1200px) {
            font-size: 20px;
            padding-left: 5px;
            margin-bottom: 18px;
            margin-top: auto;
        }

        .navopen & {
            color: $color--black;
        }

        .spacer {
            width: 20px;

        }
    }

    [data-words] {
        overflow: hidden;
    }
    [data-words]:after {
        display: none !important;
    }

    .text-anim-items {
        position: relative;
        height: 20px;
        display: inline-block;
        width: 300px;
        overflow: hidden;

    }
    .text-anim-item {
        white-space: nowrap;
        position: absolute;
        bottom:0;
        left: 0;
    }
    .text-anim-item.anim-in {
        -webkit-transform: translate3d(-50%, -120%, 0);
        transform: translate3d(0, -120%, 0);
        -webkit-animation: textAnimIn .6s .3s forwards;
        animation: textAnimIn .6s .3s forwards;
    }
    .text-anim-item.anim-out {
        -webkit-transform: translate3d(0, 0%, 0);
        transform: translate3d(0, 0%, 0);
        -webkit-animation: textAnimOut .6s  forwards;
        animation: textAnimOut .6s  forwards;
    }
    @keyframes textAnimIn {
        0% {
            -webkit-transform: translate3d(0, 120%, 0);
            transform: translate3d(0, 120%, 0);
        }
        100% {
            -webkit-transform: translate3d(0, 0%, 0);
            transform: translate3d(0, 0%, 0);
        }
    }
    @keyframes textAnimOut {
        0% {
            -webkit-transform: translate3d(0, 0%, 0);
            transform: translate3d(0, 0%, 0);
        }
        100% {
            -webkit-transform: translate3d(0, -120%, 0);
            transform: translate3d(0, -120%, 0);
        }
    }


    h2 {
        font-size: 17px;
        font-family: 'National';
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: .6px;
        display: inline-block;
        text-transform: uppercase;
        color: #fff;
        margin: 0;
        @media all and (min-width: 1200px) {
            font-size: 20px;
            padding-left: 20px;
            margin-bottom: 18px;
            margin-top: auto;
        }

        .navopen & {
            color: $color--black;
        }
    }

    nav {
        visibility: hidden;
        opacity: 0;

        @media all and (min-width: 800px) {
            visibility: visible;
            opacity: 1;
        }
        margin-top: auto;
        margin-bottom: 13px;
        @media all and (min-width: 1200px) {
            margin-bottom: 13px;
        }

        @media all and (max-width: 799px) {
            width: 100%;
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            padding: 100px 5% 40px;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
            transition: all .5s;

            ul {
                display: inline-block !important;

                li a {
                    margin-left: 0 !important;
                }
            }
        }

        .navopen & {
            visibility: visible;
            opacity: 1;
        }
    }

    nav ul {
        display: flex;
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
            list-style: none;

            &:nth-of-type(1) a {
                margin-left: 0;
            }

            a {
                font-family: 'National';
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 38px;
                @media all and (min-width: 800px) {
                    font-size: 26px;
                    line-height: 38px;
                    margin-left: 20px;
                }
                @media all and (min-width: 1100px) {
                    font-size: 28px;
                    line-height: 34px;
                    margin-left: 30px;
                }
                color: #BDBDBD;
                text-decoration: none;

                transition: all .5s;

                &:visited {
                    color: #BDBDBD;
                }

                &:hover {
                    color: $color--green;
                }

                &.red {
                    color: $color--red;

                    &:visited {
                        color: $color--red;
                    }
                }

                &.green {
                    color: $color--green;

                    &:visited {
                        color: $color--green;
                    }
                    &:hover {
                        color: $color--red;
                    }
                }
            }
        }
    }
}

#background-video {
    background: #fff3ec;
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0;
}


#main {
    position: relative;
    background: $color--grey;
    margin-top: -103px;
    .container {
        width: 100%;
        padding: 200px 5%;
        @media all and (min-width: 1300px) {
            max-width: 1200px;
            padding: 200px 50px 300px;
        }
        margin: 0 auto;
        z-index: 1;
        position: relative;

        h1 {
            font-size: 35px;
            max-width: 820px;
            margin: 0 auto 50px;
            line-height: 45px;
            @media all and (min-width: 400px) {
                font-size: 48px;
                line-height: 60px;
            }
            @media all and (min-width: 800px) {
                font-size: 120px;
                line-height: 100%;
                margin-bottom: 50px;
            }
            font-family: 'National';
            font-style: normal;
            font-weight: 300;

            /* or 96px */
            text-align: center;

            color: #fff;
        }

        .button {
            font-family: 'National';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: $color--black;
            background: $color--green;
            text-decoration: none;
            padding: 10px 20px 11px;
            margin: 0 auto 65px;
            display: table;
            border-radius: 8px;
            transition: all .5s;

            &:hover {
                background-color: $color--red;
                color: #fff;
            }
        }

        p {
            max-width: 600px;
            margin: 0 auto;
            font-size: 20px;
            line-height: 30px;
            @media all and (min-width: 800px) {
                font-size: 20px;
                line-height: 26px;
            }
            font-family: 'National';
            font-style: normal;
            font-weight: 400;

            /* or 42px */
            text-align: center;

            color: #fff;
        }
    }
}

#map {
    padding: 0 0 40px 0;
    @media all and (min-width: 800px) {
        padding: 0 0 80px 0;
    }
    position: relative;

    .container {
        width: 100%;
        padding: 0 5%;
        z-index: 1;
        position: relative;
    }

    .internal-container {
        background: #fff;
        padding: 10% 0;
        @media all and (min-width: 800px) {
            padding: 48px 0;
        }
        @media all and (min-width: 800px) {
            display: flex;
            justify-content: space-between;
            max-width: 90%;
            margin: 0 auto;
        }
        @media all and (min-width: 1100px) {
            max-width: 1000px;
        }
        flex-wrap: wrap;

        h2 {
            width: 100%;
            font-family: National;
            font-size: 60px;
            font-weight: 700;
            line-height: 72px;
            letter-spacing: 0em;
            text-align: left;
            margin: 0 0 16px 0;
        }

        .image-holder {
            width: 100%;
            height: 200px;
            object-fit: contain;
            @media all and (min-width: 500px) {
                height: 300px;
            }
            margin-bottom: 40px;
            @media all and (min-width: 800px) {
                margin-bottom: 0;
                width: 48%;
                height: 200px;
            }
            @media all and (min-width: 1000px) {
                margin-bottom: 0;
                width: 230px;
                height: 317px;
            }

            img {
                object-fit: contain;
                height: 200px;
                @media all and (min-width: 500px) {
                    height: 300px;
                }
                width: 100%;
                @media all and (min-width: 800px) {
                    height: auto;
                }
                max-width: 100%;
                max-height: 100%;
            }
        }

        .copy {
            @media all and (min-width: 800px) {
                width: 48%;
            }
            @media all and (min-width: 1000px) {
                width: calc(100% - 300px);
            }
            font-family: 'National';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #222222;
            margin: 0;
            position: relative;

            p {
                max-width: 700px;
            }

            .vert {
                width: 100%;
                @media all and (min-width: 1200px) {
                    position: absolute;
                    top: 50%;
                    transform: translatey(-50%);
                }
                margin: 0;
            }
        }
    }
}

#wealth {
    padding: 40px 0;
    @media all and (min-width: 800px) {
        padding: 80px 0;
    }
    background: #f1f1f1;

    .container {
        width: 100%;
        padding: 0 5%;
        @media all and (min-width: $container-width + 100px) {
            max-width: $container-width + 100px;
            padding: 0 50px;
        }
        margin: 0 auto;
        @media all and (min-width: 1000px) {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
        }

        .copy {
            margin-bottom: 40px;
            @media all and (min-width: 1000px) {
                width: 45%;
                margin-bottom: 0;
            }

            h2 {
                font-family: National;
                font-size: 60px;
                font-weight: 700;
                line-height: 72px;
                letter-spacing: 0em;
                margin-top: 0;
                margin-bottom: 16px;
            }

            p {
                font-family: 'National';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #222222;
                margin: 0 0 20px 0;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .video-holder {

            width: 100%;
            margin-bottom: 40px;
            @media all and (min-width: 1000px) {
                width: 51%;
                margin-bottom: 0;
            }

            img {
                max-width: 100%;
            }
        }

        .video-container {
            overflow: hidden;
            position: relative;
            width:100%;
        }

        .video-container::after {
            padding-top: 56.25%;
            display: block;
            content: '';
        }

        .video-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

#platform {
    padding: 80px 0;
    background: #fff;

    .container {
        width: 100%;
        padding: 0 5%;
        @media all and (min-width: $container-width + 100px) {
            max-width: $container-width + 100px;
            padding: 0 50px;
        }
        margin: 0 auto;
        @media all and (min-width: 800px) {
            display: flex;
            justify-content: space-between;
        }

        h2 {
            margin: 0;
            margin-bottom: 24px;
            @media all and (min-width: 800px) {
                width: 47%;
                margin-bottom :0;
            }
            font-family: 'National';
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;
            @media all and (min-width: 800px) {
                font-size: 60px;
                line-height: 72px;
            }

            color: #222222;

        }

        p {
            @media all and (min-width: 800px) {
                width: 47%;
            }
            margin: 0;

            font-family: 'National';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #222222;
        }
    }
}

#services {
    padding: 40px 0;
    @media all and (min-width: 800px) {
        padding: 55px 0 60px;
    }
    background: #fff;
    position: relative;

    .container {
        width: 100%;
        padding: 0 5%;
        @media all and (min-width: $container-width + 100px) {
            max-width: $container-width + 100px;
            padding: 0 50px;
        }
        margin: 0 auto;
        z-index: 1;
        position: relative;
    }


    .internal-container {
        background: #fff;

        h2 {
            font-family: 'National';
            font-style: normal;
            margin-top: 0;
            font-weight: 500;
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 24px;
            @media all and (min-width: 800px) {
                font-size: 60px;
                line-height: 72px;
                margin: 0 0 48px 0;
            }

            color: $color--black;
        }

        .articles {
            @media all and (min-width: 1000px) {
                display: flex;
                justify-content: space-between;
            }

            article {
                margin-bottom: 48px;
                text-align: center;
                @media all and (min-width: 1000px) {
                    margin-bottom: 0;
                    text-align: left;
                }
                @media all and (min-width: 1000px) {
                    width: 31%;
                }

                img {
                    height: 180px;
                    @media all and (min-width: 1000px) {
                        height: auto;
                    }
                    width: auto;
                    max-width: 100%;
                    object-fit: cover;
                }

                h3 {
                    margin: 20px 0 8px;
                    font-family: 'National';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 100%;
                    @media all and (min-width: 800px) {
                        font-size: 40px;
                        line-height: 120%;
                    }
                    color: #222222;
                }

                p {
                    font-family: 'National';
                    margin: 0;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: #222222;
                    margin-bottom: 20px;
                }

                a {
                    font-family: National;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #fa0101;


                }
            }
        }
    }
}

#team {
    background: $color--grey;
    padding: 40px 0;
    @media all and (min-width: 800px) {
        padding: 60px 0;
    }
    .container {
        width: 100%;
        padding: 0 5%;
        @media all and (min-width: $container-width + 100px) {
            max-width: $container-width + 100px;
            padding: 0 50px;
        }
        margin: 0 auto;

        .team {
            margin-bottom: 48px;

            h2 {
                font-family: 'National';
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 48px;
                margin-bottom: 24px;
                @media all and (min-width: 800px) {
                    font-size: 60px;
                    line-height: 72px;
                    margin-bottom: 16px;
                }
                color: #222222;
                margin-top: 0;
            }

            p {
                font-family: 'National';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 140%;
                color: #222222;
                margin: 0 0 20px 0;
            }
        }

        .people {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .member-holder {
                width: 48%;
                @media all and (max-width: 799px) {
                    margin-bottom: 48px;
                   width: 100%;
                }
            }

            .outside-copy {
                font-family: 'National';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 120%;
                margin-bottom: 40px;

                color: #222222;
                @media all and (min-width: 800px) {
                    display: none;
                }
            }

            .team-member {
                @media all and (min-width: 800px) {
                    margin-bottom: 80px;
                }
                @media all and (min-width: 400px) {
                    display: flex;
                    justify-content: space-between;
                }

                .inside-copy {
                    display: none;

                    @media all and (min-width: 800px) {
                        display: inline-block;
                    }

                }

                img {
                    width: 104px;
                    height: 104px;
                    @media all and (min-width: 800px) {
                        width: 161px;
                        height: 161px;
                    }
                    background: #dcdcdc;
                    border-radius: 50%;
                    border: none;
                }

                p {
                    margin-top: 7px;
                    margin-bottom: 0;
                    @media all and (min-width: 800px) {
                        margin-top: 0;
                    }
                    @media all and (min-width: 400px) {
                        width: calc(100% - 124px);
                    }
                    @media all and (min-width: 800px) {
                        width: calc(100% - 181px);
                    }
                    font-family: 'National';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    color: $color--black;
                    position: relative;

                    strong {
                        @media all and (min-width: 400px) and (max-width: 799px) {
                            position: absolute;
                            bottom: 10px;
                            left: 0px;
                        }
                    }


                }
            }
        }
    }
}

#investors {
    background: $color--green;
    padding: 40px 0;
    @media all and (min-width: 800px) {
        padding: 60px 0 100px;
    }

    .container {
        width: 100%;
        padding: 0 5%;
        @media all and (min-width: $container-width + 100px) {
            max-width: $container-width + 100px;
            padding: 0 50px;
        }
        margin: 0 auto;

        .investors-heading {
            margin-bottom: 48px;

            h2 {
                font-family: 'National';
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 48px;
                margin-bottom: 24px;
                @media all and (min-width: 800px) {
                    font-size: 60px;
                    line-height: 72px;
                    margin-bottom: 16px;
                }
                color: #222222;
                margin-top: 0;
            }

            p {
                font-family: 'National';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 140%;
                color: #222222;
                margin: 0 0 20px 0;
            }
        }

        .investors-body {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;

            .investor {
                width: 640px;
                padding: 72px 0 60px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                @media all and (max-width: 799px) {
                    margin-bottom: 48px;
                   width: 100%;
                }

                box-shadow: 0px 20px 20px #278557;
                background-color: #fff;

                img {
                    display: block;
                }

                img.logo {
                    margin-bottom: 20px;
                }

                img.avatar {
                    margin: 20px;
                    width: 104px;
                    height: 104px;
                    @media all and (min-width: 800px) {
                        width: 161px;
                        height: 161px;
                    }
                    background: #dcdcdc;
                    border-radius: 50%;
                    border: none;
                }

                p {
                    text-align: center;
                    margin-top: 7px;
                    margin-bottom: 0;
                    @media all and (min-width: 800px) {
                        margin-top: 0;
                    }
                    font-family: 'National';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    color: $color--black;
                    position: relative;

                    strong {
                        @media all and (min-width: 400px) and (max-width: 799px) {
                            position: absolute;
                            bottom: 10px;
                            left: 0px;
                        }
                    }


                }
            }
        }
    }
}


.footer-button {
    .button {
        font-family: 'National';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: $color--black;
        background: $color--green;
        text-decoration: none;
        padding: 10px 20px 11px;
        margin: 24px 0 36px;
        display: table;
        border-radius: 8px;
        transition: all .5s;

        &:hover {
            background-color: $color--red;
            color: #fff;
        }
    }
}

footer {
    background: $color--black;
    padding: 40px 0;
    color: #fff;

    .container {
        width: 100%;
        padding: 0 5%;
        @media all and (min-width: $container-width + 100px) {
            max-width: $container-width + 100px;
            padding: 0 50px;
        }
        margin: 0 auto;
    }

    .top-border {
        padding: 30px 0;
        border-top: 1px solid #4F4F4F;
    }

    .logo {
        z-index: 5;
        @media all and (min-width: 800px) {
            display: flex;
        }
    }


    h1 {
        @media all and (max-width: 799px) {
            margin-bottom: 0;
        }


        a {
            background-image: url('../media/wire-screen-logo.svg');
            background-repeat: no-repeat;
            width: 200px;
            height: 40px;
            background-size: contain;

            @media all and (min-width: 800px) {
                width: 300px;
                height: 60px;
            }
            display: inline-block;
            text-indent: -9999px;
            margin-top: auto;
        }
    }


    h2 {
        font-size: 17px;
        font-family: 'National';
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: .6px;
        display: inline-block;
        text-transform: uppercase;
        color: #bebebe;
        margin: 5px 0 20px 0;
        @media all and (min-width: 800px) {
            font-size: 20px;
            padding-left: 5px;
            margin-bottom: 18px;
            margin-top: auto;
        }
    }

    .footer-main-menu {
        a {
            font-family: 'National';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: #EDEDE9;
            transition: all .5s;
            @media all and (max-width: 399px) {
                margin-bottom: 20px;
                display: inline-block;
            }

            &:visited {
                color: #EDEDE9;
            }

            &:hover {
                color: $color--green;
            }

            &.red {
                color: $color--red;

                &:visited {
                    color: $color--red;
                }
            }

            &.green {
                color: $color--green;

                &:visited {
                    color: $color--green;
                }
                &:hover {
                    color: $color--red;
                }
            }
        }
    }

    .footer-sub-menu {
        a {
            font-family: 'National';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #BDBDBD;
            transition: all .5s;
            @media all and (max-width: 399px) {
                margin-bottom: 20px;
                display: inline-block;
            }

            &:visited {
                color: #BDBDBD;
            }

            &:hover {
                color: $color--green;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        @media all and (min-width: 400px) {
            display: flex;
        }

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            @media all and (min-width: 400px) {
                padding-left: 32px;
            }

            font-family: 'National';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #BDBDBD;

            &:nth-of-type(1) {
                padding-left: 0;
            }

            a {
                color: #fff;
                text-decoration: none;
            }



        }
    }
}


//Menu Toggle
//--------------------------------------------------------------
//  HAMBURGER MOBILE MENU ICON
//--------------------------------------------------------------

// http://callmenick.com/_development/css-hamburger-menu-icons/

$color__menu_toggle: #fff;
$button-width: 60px; // The width of the button area
$button-height: 60px; // The height of the button area
$bar-thickness: 2px; // The thickness of the button bars
$button-pad: 20px; // The left/right padding between button area and bars.
$button-bar-space: 6px; // The spacing between button bars
$button-transistion-duration: 0.3s; // The transition duration
$bp__tablet: 756px;
$bp__menu_switch: 1000px;
$height__page_header_medium: 100px;


.menu-toggle {
    background: transparent;
    border: none;
    color: $color__menu_toggle;
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    width: $button-width;
    height: $button-height;
    font-size: 0;
    cursor: pointer;
    transition: background $button-transistion-duration, margin-top $button-transistion-duration;
    text-transform: none;
    margin-top: 20px;
    margin-right: -20px;

    &:hover {
        background: none;
    }

    @media all and (min-width: 800px) {
        @include hide;
    }

    .navopen & span::before,
    .navopen & span::after {
        background-color: $color--black;
    }
}

.menu-toggle:focus {
    outline: none;
}

.menu-toggle span {
    display: block;
    font-size: 0;
    position: absolute;
    top: calc($button-height / 2);
    left: $button-pad;
    right: $button-pad;
    height: $bar-thickness;
    background: $color__menu_toggle;

    @media all and (min-width: 756px) {
        .at-top & {
            top: calc($height__page_header_medium / 2);
        }
    }
}

.menu-toggle span::before,
.menu-toggle span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: $bar-thickness;
    background-color: $color__menu_toggle;
    content: "";
}

.menu-toggle span::before {
    top: -$bar-thickness - $button-bar-space;
}

.menu-toggle span::after {
    bottom: -$bar-thickness - $button-bar-space;
}

/**
 * Hamburger to "x". Takes on a hamburger shape, bars slide
 * down to center and transform into an "x".
 */

.menu-toggle span {
    transition: background 0s $button-transistion-duration;
}

.menu-toggle span::before,
.menu-toggle span::after {
    transition-duration: $button-transistion-duration, $button-transistion-duration;
    transition-delay: $button-transistion-duration, 0s;
}

.menu-toggle span::before {
    transition-property: top, transform;
}

.menu-toggle span::after {
    transition-property: bottom, transform;
}

.menu-toggle.is-active:after {
    content: 'Close';
}

.menu-toggle.is-active span {
    background: none;
}

.menu-toggle.is-active span::before {
    top: 0;
    transform: rotate(45deg);
}

.menu-toggle.is-active span::after {
    bottom: 0;
    transform: rotate(-45deg);
}

.menu-toggle.is-active span::before,
.menu-toggle.is-active span::after {
    transition-delay: 0s, $button-transistion-duration;
}